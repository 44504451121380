import React, { Component } from "react";
import { withPrefix } from "gatsby";
import { Helmet } from "react-helmet";
import debounce from "lodash.debounce";
import logoBlackWithBubble from "../images/tuzag_black_with_message_bubble.svg";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Intercom from "react-intercom";
import ReactGA from "react-ga";
import ReCAPTCHA from "react-google-recaptcha";
import navLogo from "../images/logo_nav.svg";
import firstScreenShot from "../images/firstScreenShot.png";
import secondScreenShot from "../images/secondScreenShot.png";
import hikerForest from "../images/hikerForest.jpg";
import flowDesktop from "../images/mhdFlowDesktop.png";
import flowMobile from "../images/mhdFlowMobile.png";
import mhdSquare from "../images/myHealthyDayLogo.svg";
import field from "../images/field.png";
import { Element } from "react-scroll";
import Modal from "react-modal";

const tailor = async (messageID, data = {}) => {
	let apiURL;

	if (process.env.GATSBY_REACT_APP_ENV === "staging") {
		apiURL = "https://api.staging.tuzagdesign.com/tailor/message";
	} else {
		apiURL =
			process.env.NODE_ENV === "development"
				? "http://localhost:3000/tailor/message"
				: "https://api.v2.tuzagdesign.com/tailor/message";
	}

	data.apiKey = "nRvtIpRUNsxAuTUQ3w8DnRvtIpRUNsxAuTUQ3w8D";
	data.returnElementSlugs = true;

	const unParsedData = await fetch(`${apiURL}/${messageID}`, {
		method: "POST",
		credentials: "include",
		mode: "cors",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	});

	return await unParsedData.json();
};

export default class Home extends Component {
	state = { scrollTop: 0, trialModalOpen: false };

	componentDidMount = async () => {
		ReactGA.initialize("UA-67845484-8");
		ReactGA.set({
			page: window.location.pathname + window.location.search,
		});
		ReactGA.pageview(window.location.pathname + window.location.search);

		window.addEventListener("scroll", this.setScrollDataInState);

		this.setState({
			tailorData: await tailor("4251,4252,4253,4254,4255,4256,4257,4258,4259"),
		});
	};

	tailorData(key, mid, raw = false) {
		try {
			if (this.state.tailorData) {
				if (raw) {
					return this.state.tailorData[mid][key];
				}
				return (
					<span
						dangerouslySetInnerHTML={{
							__html: this.state.tailorData[mid][key],
						}}
					/>
				);
			} else {
				return <span />;
			}
		} catch (e) {
			//do nothing
		}
	}

	setScrollDataInState = debounce(() => {
		this.setState({
			scrollTop: document.body.scrollTop || document.documentElement.scrollTop,
		});
	}, 50);

	onChange = (value) => {
		this.setState({ recaptcha: value });
	};

	render() {
		return (
			<div>
				<Helmet>
					{/*<script src={withPrefix("static/theme-js/jquery-1.11.3.min.js")} />*/}
					<script src={withPrefix("static/theme-js/bootstrap.js")} />
					<script src={withPrefix("static/theme-js/owl.carousel.min.js")} />
					<script src={withPrefix("static/theme-js/jquery.scrollTo-min.js")} />
					<script
						src={withPrefix("static/theme-js/jquery.magnific-popup.min.js")}
					/>
					<script src={withPrefix("static/theme-js/jquery.nav.js")} />
					<script src={withPrefix("static/theme-js/wow.js")} />
					<script src={withPrefix("static/theme-js/jquery.vegas.js")} />
					<script src={withPrefix("static/theme-js/plugins.js")} />
					<script src={withPrefix("static/theme-js/custom.js")} />
					<link
						href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
						rel="stylesheet"
						integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
						crossOrigin="anonymous"
					/>
					<link
						href="https://fonts.googleapis.com/css?family=Open+Sans:400,700|Cabin:500|Raleway&display=swap"
						rel="stylesheet"
					/>
					<link
						href="https://assets.calendly.com/assets/external/widget.css"
						rel="stylesheet"
					/>
					<script
						src="https://assets.calendly.com/assets/external/widget.js"
						type="text/javascript"
					/>
				</Helmet>
				{!this.state.tailorData ? (
					<div className="container text-center">
						<img
							src={navLogo}
							alt="MyHealthyDay logo"
							style={{ marginTop: 40, marginBottom: 40 }}
						/>
						<h1>
							<i
								className="fa fa-circle-o-notch fa-spin"
								aria-hidden="true"
								style={{ fontSize: 72, marginTop: 50 }}
							/>
						</h1>
					</div>
				) : (
					<div>
						<Navbar
							tailorData={this.state.tailorData}
							scrollTop={this.state.scrollTop}
						/>
						<section className="home-section" style={{ paddingTop: 140 }}>
							<div className="home-section-overlay" />

							<div className="container">
								<div className="row">
									<div className="col-sm-8 text-center">
										<div className="hidden-xs" style={{ paddingTop: 100 }} />
										<h3
											style={{
												color: "white",
												fontWeight: 600,
											}}
										>
											{this.tailorData("sentence_1", 4252)}
										</h3>

										<h3
											style={{
												fontStyle: "italic",
												color: "white",
												marginTop: 50,
												marginBottom: 50,
												fontWeight: 400,
											}}
										>
											{this.tailorData("sentence_2", 4252)}
										</h3>
										{/*<a*/}
										{/*	className="popup4 btn-blue"*/}
										{/*	href="https://www.youtube.com/watch?v=FPfQMVf4vwQ"*/}
										{/*>*/}
										{/*	Watch the Demo*/}
										{/*</a>*/}

										{/*<a href="#" className="btn-white">*/}
										{/*	Explore Benefits*/}
										{/*</a>*/}
									</div>
									<div className="col-sm-4 text-center">
										<img
											src={firstScreenShot}
											style={{ width: "100%", maxWidth: 300 }}
											alt="MyHealthyDay app screenshot"
										/>
									</div>
								</div>
							</div>
						</section>
						<section
							style={{
								backgroundImage: `url(${hikerForest})`,
								backgroundPosition: "center",
								backgroundSize: "cover",
							}}
						>
							<div className="container">
								<div className="row">
									<div
										className="col-md-6 col-md-offset-3 col-xs-10 col-xs-offset-1 text-center"
										style={{
											background: "rgba(255,255,255,.85)",
											borderRadius: 8,
											boxShadow: "2px 2px 10px gray",
											marginTop: 150,
											marginBottom: 150,
											paddingTop: 25,
											paddingBottom: 25,
										}}
									>
										<p>{this.tailorData("introduction", 4253)}</p>
										<h3 style={{ fontWeight: 700 }}>
											{this.tailorData("cta", 4253)}
										</h3>
										<a href="#contact">
											<button
												className="btn btn-default"
												style={{
													width: "50%",
													backgroundColor: "#1E4870",
													color: "white",
													padding: 15,
													minWidth: 170,
												}}
											>
												{this.tailorData("buttonLabel", 4253)}
											</button>
										</a>
									</div>
								</div>
							</div>
						</section>
						<Element name="product" />
						<section className="container" id="product">
							<div className="row">
								<div className="col-md-10 col-md-offset-1">
									<div className="text-center">
										<h3>{this.tailorData("headline", 4254)}</h3>
										<p>{this.tailorData("introduction", 4254)}</p>
									</div>
									<div className="row">
										<div className="col-sm-5 text-center">
											<img
												alt="MyHealthyDay app screenshot showing a chat message screen"
												src={secondScreenShot}
												style={{
													maxWidth: 300,
													width: "100%",
													marginBottom: 25,
												}}
											/>
										</div>
										<div className="col-sm-7">
											<p>{this.tailorData("body_1", 4254)}</p>
											<p>{this.tailorData("body_2", 4254)}</p>
										</div>
									</div>
									{/*<div className="row">*/}
									{/*	<div className="col-sm-12" />*/}
									{/*</div>*/}
								</div>
							</div>
						</section>
						<Element name="experience" />
						<section style={{ backgroundColor: "#F0F0F0" }} id="experience">
							<div className="container">
								<div className="row">
									<div className="col-md-10 col-md-offset-1">
										<div className="text-center">
											<h3>{this.tailorData("headline", 4255)}</h3>
										</div>
										<p>{this.tailorData("introduction", 4255)}</p>
									</div>
								</div>
								<div className="row">
									<div className="col-xs-12 hidden-xs">
										<img
											src={flowDesktop}
											style={{ width: "100%" }}
											alt="diagram describing the user flow of MyHealthyDay"
										/>
									</div>
									<div className="col-xs-12 hidden-sm hidden-md hidden-lg">
										<img
											src={flowMobile}
											style={{ width: "100%" }}
											alt="diagram describing the user flow of MyHealthyDay"
										/>
									</div>
								</div>
							</div>
						</section>
						{/*<Element name="trial" />*/}
						{/*<section className="container" id="trial">*/}
						{/*	<div className="text-center">*/}
						{/*		<h3>{this.tailorData("headline", 4259)}</h3>*/}
						{/*	</div>*/}
						{/*	<div className="row" style={{ marginBottom: 35 }}>*/}
						{/*		<div className="col-md-3 col-md-offset-2 text-center">*/}
						{/*			<img*/}
						{/*				src={mhdSquare}*/}
						{/*				alt="MyHealthyDay logo"*/}
						{/*				style={{ width: "100%", maxWidth: 225, marginBottom: 25 }}*/}
						{/*			/>*/}
						{/*		</div>*/}
						{/*		<div className="col-md-5">*/}
						{/*			<div className="hidden-xs" style={{ marginTop: 35 }} />*/}
						{/*			<div className="hidden-md hidden-lg text-center">*/}
						{/*				<p>{this.tailorData("body", 4259)}</p>*/}
						{/*			</div>*/}
						{/*			<div className="hidden-sm hidden-xs">*/}
						{/*				<p>{this.tailorData("body", 4259)}</p>*/}
						{/*			</div>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*	<div className="row">*/}
						{/*		<div className="col-xs-12 text-center">*/}
						{/*			<button*/}
						{/*				className="btn btn-default"*/}
						{/*				style={{*/}
						{/*					width: "40%",*/}
						{/*					backgroundColor: "#1E4870",*/}
						{/*					color: "white",*/}
						{/*					padding: 15,*/}
						{/*					minWidth: 170,*/}
						{/*					marginBottom: 30*/}
						{/*				}}*/}
						{/*				onClick={() => {*/}
						{/*					this.setState({ trialModalOpen: true });*/}
						{/*				}}*/}
						{/*			>*/}
						{/*				{this.tailorData("buttonLabel", 4259)}*/}
						{/*			</button>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*</section>*/}
						<Element name="about" />
						<section
							style={{
								backgroundImage: `url(${field})`,
								backgroundPosition: "center",
								backgroundSize: "cover",
							}}
							id="about"
						>
							<div className="container">
								<div className="row">
									<div
										className="col-md-6 col-md-offset-3 col-xs-10 col-xs-offset-1"
										style={{
											background: "rgba(255,255,255,.85)",
											borderRadius: 8,
											boxShadow: "2px 2px 10px gray",
											marginTop: 150,
											marginBottom: 150,
											paddingTop: 25,
											paddingBottom: 25,
										}}
									>
										<div className="row" style={{ display: "flex" }}>
											<div className="col-md-7">
												<p>{this.tailorData("body", 4256)}</p>
											</div>
											<div
												className="col-md-5 hidden-sm hidden-xs"
												style={{ display: "flex" }}
											>
												<img
													src={logoBlackWithBubble}
													style={{ width: "100%", maxWidth: 300 }}
													alt="tuzag logo"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<Element name="contact" />
						<section className="section-white" id="contact">
							<div className="container">
								<div className="row">
									<div className="col-md-10 col-md-offset-1 text-center margin-bottom-30">
										<h2 className="section-title">
											{this.tailorData("headline", 4257)}
										</h2>
									</div>
								</div>

								<div className="row margin-20">
									{this.state.contactFormSubmitted ? (
										<div className="text-center">
											<p
												className="contact_success_box"
												style={{ color: "black", fontStyle: "normal" }}
											>
												{this.tailorData("postSendMessage", 4257)}
											</p>
										</div>
									) : (
										<form
											id="contact-form"
											className="contact"
											onSubmit={async (evt) => {
												evt.preventDefault();
												const formData = {
													name: document.getElementById("contact-names").value,
													email: document.getElementById("contact-email").value,
													phone: document.getElementById("contact-phone").value,
													message: document.getElementById("contact-comment")
														.value,
													recaptchaToken: this.state.recaptcha,
												};

												let apiURL;

												if (process.env.GATSBY_REACT_APP_ENV === "staging") {
													apiURL =
														"https://api.staging.tuzagdesign.com/marketingSiteMessage";
												} else {
													apiURL =
														process.env.NODE_ENV === "development"
															? "http://localhost:3000/marketingSiteMessage"
															: "https://api.v2.tuzagdesign.com/marketingSiteMessage";
												}

												fetch(apiURL, {
													method: "POST",
													credentials: "include",
													mode: "cors",
													headers: {
														"Content-Type": "application/json",
													},
													body: JSON.stringify(formData),
												});

												this.setState({ contactFormSubmitted: true });
											}}
										>
											<div className="col-md-6">
												<div className="row">
													<div
														className="col-xs-2 text-right"
														style={{
															paddingRight: 0,
															paddingTop: 18,
														}}
													>
														<label
															htmlFor="contact-names"
															style={{ display: "block" }}
														>
															{this.tailorData("nameLabel", 4257, true)}
														</label>
													</div>
													<div className="col-xs-10">
														<input
															className="contact-input"
															placeholder={this.tailorData(
																"namePlaceholder",
																4257,
																true
															)}
															type="text"
															id="contact-names"
														/>
													</div>
												</div>

												<div className="row">
													<div
														className="col-xs-2 text-right"
														style={{
															paddingRight: 0,
															paddingTop: 18,
														}}
													>
														<label
															htmlFor="contact-email"
															style={{ display: "block" }}
														>
															{this.tailorData("emailLabel", 4257)}
														</label>
													</div>
													<div className="col-xs-10">
														<input
															className="contact-input"
															placeholder={this.tailorData(
																"emailPlaceholder",
																4257,
																true
															)}
															type="text"
															id="contact-email"
														/>
													</div>
												</div>

												<div className="row">
													<div
														className="col-xs-2 text-right"
														style={{
															paddingRight: 0,
															paddingTop: 18,
														}}
													>
														<label
															htmlFor="contact-phone"
															style={{ display: "block" }}
														>
															{this.tailorData("phoneLabel", 4257)}
														</label>
													</div>
													<div className="col-xs-10">
														<input
															className="contact-input"
															placeholder={this.tailorData(
																"phonePlaceholder",
																4257,
																true
															)}
															type="text"
															id="contact-phone"
														/>
													</div>
												</div>

												<div className="row">
													<div
														className="col-xs-2 text-right"
														style={{
															paddingRight: 0,
															paddingTop: 18,
														}}
													>
														<label style={{ display: "block" }}>
															{this.tailorData("meetLabel", 4257)}
														</label>
													</div>
													<div className="col-xs-10">
														<button
															className="btn btn-default"
															style={{
																width: "100%",
																borderColor: "gray",
																paddingTop: 15,
																paddingBottom: 15,
																textAlign: "left",
																fontWeight: 400,
																color: "gray",
																fontFamily: "'Open Sans', sans-serif",
															}}
															ref={(el) => {
																if (el) {
																	el.style.setProperty(
																		"font-size",
																		"14px",
																		"important"
																	);
																}
															}}
															onClick={() => {
																window.Calendly.initPopupWidget({
																	url: "https://calendly.com/neal-sofian",
																});
																return false;
															}}
															type="button"
														>
															{this.tailorData("meetText", 4257, true)}
														</button>
													</div>
												</div>
											</div>

											<div className="col-md-6">
												<div
													className="hidden-md hidden-lg"
													style={{ marginTop: 20 }}
												/>

												<div className="row">
													<div
														className="col-xs-2 text-right"
														style={{
															paddingRight: 0,
															paddingTop: 18,
														}}
													>
														<label
															htmlFor="contact-comment"
															style={{ display: "block" }}
														>
															{this.tailorData("messageLabel", 4257)}
														</label>
													</div>
													<div className="col-xs-10">
														<textarea
															className="contact-commnent"
															rows="2"
															cols="20"
															name="contact_message"
															placeholder={this.tailorData(
																"messagePlaceholder",
																4257,
																true
															)}
															id="contact-comment"
														/>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-10 col-sm-offset-2 col-xs-12">
														{this.state.recaptcha ? (
															<button
																className="btn btn-default"
																style={{
																	borderColor: "#390A4E",
																	paddingTop: 15,
																	paddingBottom: 15,
																	paddingLeft: 50,
																	paddingRight: 50,
																	width: "100%",
																	minWidth: 170,
																}}
																type="submit"
															>
																{this.tailorData("buttonLabel", 4257)}
															</button>
														) : (
															<ReCAPTCHA
																sitekey="6Lf1GKoUAAAAAMveW4W4SgcKJVPvHf4FXZUQBzM4"
																className="recaptcha"
																onChange={this.onChange}
															/>
														)}
													</div>
												</div>
											</div>
										</form>
									)}
								</div>
							</div>
						</section>
						<Footer tailorData={this.state.tailorData} />
						<Modal
							isOpen={this.state.trialModalOpen}
							onRequestClose={() => {
								this.setState({ trialModalOpen: false });
							}}
							style={{
								content: {
									top: "50%",
									left: "50%",
									right: "auto",
									bottom: "auto",
									marginRight: "-50%",
									transform: "translate(-50%, -50%)",
									width: "40%",
									minWidth: 300,
									// height: "50%",
									// minHeight: 200
								},
							}}
						>
							<div className="text-center">
								<h3>{this.tailorData("modalHeadline", 4259)}</h3>
							</div>
							{this.state.trialFormSubmitted ? (
								<div>
									<p>{this.tailorData("modalCheckYourEmail", 4259)}</p>
								</div>
							) : (
								<div>
									<div className="row">
										<div className="col-md-12">
											<p>{this.tailorData("modalInstructions", 4259)}</p>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="trialName">
													{this.tailorData("modalNameLabel", 4259)}
												</label>
												<input
													type="text"
													className="form-control"
													id="trialName"
													placeholder={this.tailorData(
														"modalNamePlaceholder",
														4259,
														true
													)}
													value={this.state.trialName}
													onChange={(evt) => {
														this.setState({ trialName: evt.target.value });
													}}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="trialEmail">
													{this.tailorData("modalEmailLabel", 4259)}
												</label>
												<input
													type="email"
													className="form-control"
													id="trialEmail"
													placeholder={this.tailorData(
														"modalEmailPlaceholder",
														4259,
														true
													)}
													value={this.state.trialEmail}
													onChange={(evt) => {
														this.setState({ trialEmail: evt.target.value });
													}}
												/>
											</div>
										</div>
									</div>
									<div className="text-center">
										{this.state.recaptcha ? (
											<button
												className="btn btn-default"
												style={{
													borderColor: "#1E4870",
													paddingTop: 15,
													paddingBottom: 15,
													paddingLeft: 50,
													paddingRight: 50,
													width: "100%",
													minWidth: 170,
												}}
												type="submit"
												onClick={() => {
													let apiURL;

													if (process.env.GATSBY_REACT_APP_ENV === "staging") {
														apiURL =
															"https://api.staging.tuzagdesign.com/mhdAdminV2/marketingTrial";
													} else {
														apiURL =
															process.env.NODE_ENV === "development"
																? "http://localhost:3000/mhdAdminV2/marketingTrial"
																: "https://api.v2.tuzagdesign.com/mhdAdminV2/marketingTrial";
													}

													fetch(apiURL, {
														method: "POST",
														credentials: "include",
														mode: "cors",
														headers: {
															"Content-Type": "application/json",
														},
														body: JSON.stringify({
															recaptchaToken: this.state.recaptcha,
															email: this.state.trialEmail,
															name: this.state.trialName,
														}),
													});

													this.setState({ trialFormSubmitted: true });
												}}
											>
												{this.tailorData("modalButtonLabel", 4259)}
											</button>
										) : (
											<ReCAPTCHA
												sitekey="6Lf1GKoUAAAAAMveW4W4SgcKJVPvHf4FXZUQBzM4"
												className="recaptcha"
												onChange={this.onChange}
											/>
										)}
									</div>
								</div>
							)}
						</Modal>
					</div>
				)}
				<Intercom appID="a48abriy" />
			</div>
		);
	}
}
